export interface ISeason {
  year: string
  slug: string
  remoteSlug: string
  id: number
}

// First season in array is exported as the current one
const seasonsInOrder: ISeason[] = [
  { year: '2023/24', slug: 'latest', remoteSlug: '2023-24', id: 25 },
  { year: '2022/23', slug: '2022-23', remoteSlug: '2022-23', id: 24 },
]

const getRemoteSeasonSlug = (seasonSlug: string): string =>
  seasonsInOrder.find((season) => season.slug === seasonSlug).remoteSlug

module.exports = {
  seasons: seasonsInOrder,
  latestSeason: seasonsInOrder[0],
  previousSeason: seasonsInOrder[1],
  getRemoteSeasonSlug,
}
