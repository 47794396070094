import { ICategory } from 'src/components/side-menu/sub-elements/Category'
import useBasicLawsMenuStructure from 'src/components/side-menu/side-menu-structure/basic-laws'
import useGlossaryMenuStructure from 'src/components/side-menu/side-menu-structure/glossary'
import useGuidelinesMenuStructure from 'src/components/side-menu/side-menu-structure/guidelines'
import useLawsChangesMenuStructure from 'src/components/side-menu/side-menu-structure/law-changes'
import lawsStore from 'src/components/laws/laws-store'
import { ISubCategory } from 'src/components/side-menu/sub-elements/SubCategory'

const slugOfSeason20and21 = '2020-21'
const slugOfSeason21and22 = '2021-22'

const useMenuStructure = (): ICategory[] => {
  const basicLawsStructure = useBasicLawsMenuStructure()
  const glossaryStructure = useGlossaryMenuStructure()
  const guidelinesStructure = useGuidelinesMenuStructure()
  const lawChangesMenuStructure = useLawsChangesMenuStructure()
  const selectedSeason = lawsStore((state) => state.selectedSeason)

  const isSeason20and21 = () => selectedSeason.slug === slugOfSeason20and21
  const isSeason21and22 = () => selectedSeason.slug === slugOfSeason21and22

  const getTemporaryLaws = (): Array<ICategory> => {
    if (isSeason21and22()) {
      return [
        {
          name: 'Temporary Laws',
          id: 'temporary-laws',
          subcategories: [
            {
              name: 'Additional Substitutes',
              id: 'additional-substitutes',
              link: `/laws/${selectedSeason.slug}/temporary-amendment-covid/`,
            },
          ],
        },
      ]
    }

    return []
  }

  const getFifaQualityProgramme = (): Array<ISubCategory> => {
    if (isSeason20and21()) {
      return []
    }

    return [
      {
        name: 'FIFA Quality Programme',
        id: 'fifa-quality-program',
        link: `/laws/${selectedSeason.slug}/fifa-quality-programme/`,
      },
    ]
  }

  const getAboutTheLawsOrIntroduction = (): ISubCategory => {
    if (isSeason20and21()) {
      return {
        name: 'Introduction',
        id: 'about-the-laws',
        link: `/laws/${selectedSeason.slug}/about-the-laws/`,
      }
    }

    return {
      name: 'About the laws',
      id: 'about-the-laws',
      link: `/laws/${selectedSeason.slug}/about-the-laws/`,
    }
  }

  const getNotesAndModifications = (): ISubCategory => {
    if (isSeason21and22()) {
      return {
        name: 'Notes and modifications',
        id: 'notes-and-modifications',
        link: `/laws/${selectedSeason.slug}/notes-and-modifications/`,
      }
    }

    return {
      name: 'Notes and modifications',
      id: 'notes-and-modifications',
      elements: [
        {
          name: 'Notes on the Laws',
          id: 'notes-on-the-laws',
          link: `/laws/${selectedSeason.slug}/notes-on-the-laws/`,
        },
        {
          name: 'General Modifications',
          id: 'general-modifications',
          link: `/laws/${selectedSeason.slug}/general-modifications/`,
        },
        {
          name: 'Guidelines for Temporary Dismissals',
          id: 'guidelines-for-temporary-dismissals',
          link: `/laws/${selectedSeason.slug}/guidelines-for-temporary-dismissals/`,
        },
        {
          name: 'Guidelines for Return Substitutes',
          id: 'guidelines-for-return-substitutes',
          link: `/laws/${selectedSeason.slug}/guidelines-for-return-substitutes/`,
        },
      ],
    }
  }

  const getEditorialChanges = (): ISubCategory[] => {
    if (!isSeason20and21()) {
      return []
    }

    return [
      {
        name: 'Editorial changes',
        id: 'editorial-changes',
        link: `/laws/${selectedSeason.slug}/editorial-changes/`,
      },
    ]
  }

  return [
    {
      name: 'Laws of the game',
      isSeasonsSwitch: true,
      id: 'laws-of-the-game',
      subcategories: [
        getAboutTheLawsOrIntroduction(),
        getNotesAndModifications(),
        basicLawsStructure,
        {
          name: 'VAR protocol',
          id: 'var-protocol',
          link: `/laws/${selectedSeason.slug}/video-assistant-referee-var-protocol/`,
        },
        ...getFifaQualityProgramme(),
        glossaryStructure,
        guidelinesStructure,
        ...getEditorialChanges(),
        {
          name: 'PDF Downloads',
          id: 'laws-of-the-game-documents',
          link: '/laws-of-the-game-documents/',
        },
        {
          name: 'Audio Files',
          id: 'laws-of-the-game-audio',
          link: '/laws-of-the-game-audio/',
        },
      ],
    },
    lawChangesMenuStructure,
    ...getTemporaryLaws(),
    {
      name: 'Trials',
      id: 'trials',
      subcategories: [
        {
          name: 'Concussion substitutes',
          id: 'concussion-substitutes',
          link: `/trials/concussion-substitutes/`,
        },
        {
          name: 'Deliberate heading',
          id: 'deliberate-heading ',
          link: `/trials/deliberate-heading-trial/`,
        },
        {
          name: 'Body cameras',
          id: 'body-cameras',
          link: `/trials/body-cameras/`,
        },
      ],
    },
    {
      name: 'The IFAB',
      id: 'the-ifab',
      subcategories: [
        {
          name: 'Background',
          id: 'background',
          link: `/background/`,
        },
        {
          name: 'Organisation',
          id: 'organisation',
          link: `/organisation/`,
        },
        {
          name: 'Work process',
          id: 'work-process',
          link: `/work-process/`,
        },
        {
          name: 'Calendar',
          id: 'calendar',
          link: `/calendar/`,
        },
      ],
    },
    {
      name: 'Documents',
      id: 'documents-category',
      link: '/documents/',
    },
    {
      name: 'News',
      id: 'news',
      link: '/news/',
    },
  ]
}

export default useMenuStructure
